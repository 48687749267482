html {
  font-size: 62.5%;
}

html, body {
  height: 100%;
}

body {
  background-color: #144037;
}

.page-container {
  opacity: 0;
}

.social-links {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 63%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.social-links__item {
  margin-left: 2.5rem;
}

.social-links__item:first-child {
  margin-left: 0;
}

.social-link {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  letter-spacing: 0.2rem;
  text-decoration: none;
  color: #f0ebd8;
  font-size: 1.6rem;
  position: relative;
}

.social-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 0.2rem;
  top: calc(100% + 0.2rem);
  left: 0;
  background-color: #f0ebd8;
  transform-origin: left center;
  transform: scaleX(0);
  transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

@media (min-width: 768px) {
  .social-link:hover::after {
    transform: scaleX(1);
  }
}

#canvas-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}